import React from 'react'
import NavigationCheck from 'src/images/icons/navigation-check.svg'

// see also
// import DoneCircle from 'src/images/icons/done-circle.svg'

const SoftGreenCheckmarkBadge = () => {
  const badgeStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
  return (
    <div style={badgeStyle} className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
      <NavigationCheck fill='#42BA96'/>
    </div>
    )
}

export default SoftGreenCheckmarkBadge
