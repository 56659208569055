import React, {useEffect, useState} from "react"
import { navigate } from 'gatsby'
import Spinner from 'react-bootstrap/spinner'
import useFirebase from 'src/components/useFirebase'
import Layout from "src/components/layout"
import SEO from 'src/components/seo'
import { createBetaUserSubscription } from 'src/utilities/http-requests'
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import SupportHelmet from 'src/components/SupportHelmet'
import { identify, trackPage, trackEvent} from 'src/utilities/analytics'
import Curve1 from 'src/images/shapes/curve-1.svg'
import Blur3 from 'src/images/shapes/blur-3.svg'
import SoftGreenCheckmarkBadge from 'src/components/shared/badges/SoftGreenCheckmarkBadge'
import ArrowRight from 'src/images/icons/arrow-right.svg'

// MARK: - BETA

const pageName = 'Beta Plans'

const CardLineItem = ({text}) => {
  const badgeStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
  return (
    <div class="d-flex">
      <SoftGreenCheckmarkBadge/>
      <p>
        {text}
      </p>
    </div>
    )
}

const Card = ({title, subtitle, lineItems, isEmphasized, buttonText, priceAmount, buttonOnClick, isButtonLoading,}) => {
  const buttonArrowStyle = {
    width: 17,
    height: 17,
  }
  const buttonArrowFill = isEmphasized ? 'white' : '#335eea'
  const buttonClassNameModifier = isEmphasized ? 'btn-primary' : 'btn-primary-soft'
  const buttonClassName = `btn btn-block ${buttonClassNameModifier}`

  const spinnerVariant = isEmphasized ? 'light' : 'primary'
  const rightArrow = <ArrowRight fill={buttonArrowFill} style={buttonArrowStyle} class="fe fe-arrow-right ml-3"/>
  const loadingSpinner = <Spinner animation="border" variant={spinnerVariant}/>
  const buttonContent = isButtonLoading ? loadingSpinner : [buttonText, rightArrow]
  return (
    <div class="col-12 col-md-4">
      <div class="card shadow-lg mb-6 mb-md-0">
        <div class="card-body">
          <div class="text-center mb-3">
            <span class="badge badge-pill badge-primary-soft">
              <span class="h6 text-uppercase">
                {title}
              </span>
            </span>
          </div>
          <div class="d-flex justify-content-center">
            <span class="h2 mb-0 mt-2">$</span>
            <span class="price display-2 mb-0" data-annual="0" data-monthly="0">
              {priceAmount}
            </span>
          </div>
          <p class="text-center text-muted mb-5">
            {subtitle}
          </p>
          {
            lineItems.map((item) => <CardLineItem text={item.text} key={item.text}/>)
          }
          <button class={buttonClassName} onClick={buttonOnClick} disabled={isButtonLoading}>
            {buttonContent}
          </button>
        </div>
      </div>
    </div>
    )
}

const Question = ({question, answer}) => {
  return (
    <div>
      <h3>
        {question}
      </h3>
      <p class="text-gray-800 mb-6 mb-md-8">
        {answer}
      </p>
    </div>
    )
}

const FAQ = () => {

  const item1 = {
    question: 'What is NextLevelResume.co?',
    answer: '98% of job seekers are eliminated in resume screening. NextLevelResume.co helps you\
     build a professionally-designed resume in 5 minutes.',
  }
  const item2 = {
    question: 'How do I use NextLevelResume.co?',
    answer: 'Fill in your details, choose from one of\
     10 templates, and download your resume as a PDF!',
  }

  const item3 = {
    question: 'Is it free?',
    answer: 'Yes! As a beta tester, you get FREE access to all premium features until March 10.',
  }

  return (
    <section class="py-8 py-md-11 bg-white border-bottom">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-md-8 text-center">
            <span class="badge badge-pill badge-primary-desat-soft mb-3">
              <span class="h6 text-uppercase">FAQ</span>
            </span>
            <h2>
              Frequently asked questions.
            </h2>
            <p class="font-size-lg text-muted mb-7 mb-md-9">
              Let us help answer the most common questions you might have.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <Question {...item1}/>
            <Question {...item3}/>
          </div>
          <div class="col-12 col-md-6">
            <Question {...item2}/>
          </div>
        </div>
      </div>
    </section>
    )
}

const CardsSection = ({currentUser, onCTAClickIfLoggedIn, loadingButtonIndex, signInAnonymously,}) => {
  const baseItems = [
    {text: 'Unlimited PDF Downloads'},
    {text: 'Save Up to 10 Different Resumes'},
  ]
  const basicLineItems = [
    ...baseItems,
  ]
  const rowStyle = {
    display: 'flex',
    justifyContent: 'center',
  }
  const onCTAClick = () => {
    trackEvent('clicked_beta_cta', {page: pageName})
    if (!currentUser) {
      signInAnonymously()
      .then(currentUser => {
        onCTAClickIfLoggedIn(currentUser)
      })
      return
    }
    onCTAClickIfLoggedIn(currentUser)
  }

  return (
    <section class="mt-n8 mt-md-n14">
      <div class="container">
        <div class="form-row" style={rowStyle}>
          <Card
            title="Free Beta Membership"
            subtitle="Try us out"
            lineItems={basicLineItems}
            buttonText="Create Resume"
            isEmphasized={true}
            priceAmount='0'
            buttonOnClick={onCTAClick}
            isButtonLoading={loadingButtonIndex == 0}
          />
        </div>
      </div>
    </section>
    )
}

// accepts a url param successURL to redirect users to after they successfully check out
const Beta = ({location}) => {
  const firebase = useFirebase();
  const [currentUser, setCurrentUser] = useState(null)
  // determine which, if any, card buttons are showing loading spinner
  // value is either null (no buttons loading), 0, 1, 2 - referring to index left to right
  const [loadingButtonIndex, setLoadingButtonIndex] = useState(null)

  const signInAnonymously = () => {
    if (!firebase) return
    return firebase.auth().signInAnonymously()
      .then(userCredential => {
        // returns anonymous currentUser
        return userCredential.user
      })
      .catch(error => console.error('signInAnonymously error', error))
  }
  const onCTAClickIfLoggedIn = (currentUser) => {
    setLoadingButtonIndex(0)
    createBetaUserSubscription({userUID: currentUser.uid})
    .then(() => {
      navigate('/resume-templates')
      setLoadingButtonIndex(null)
    })
    .catch(error => {
      console.error('createBetaUserSubscription error', error)
      setLoadingButtonIndex(null)
    })
  }
  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      identify(user)
    });
    }, [firebase]);

  useEffect(() => {
    trackPage(pageName)
  }, [])

  return (
    <Layout>
      <SEO title='Build a Beautiful Resume in 5 Minutes'/>
      <AnalyticsHelmet/>
      <SupportHelmet/>
      <section class="pt-8 pt-md-11 pb-10 pb-md-15 bg-primary">
        <div class="shape shape-blur-3 svg-shim text-white">
          <Blur3/>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">
              <h1 class="display-2 text-white">
                The best way to build a resume.
              </h1>
              <p class="lead text-white-80 mb-6 mb-md-8">
                Get the job you know you deserve. Take your resume to the next level.
              </p>

            </div>
          </div>
        </div>
      </section>

      <div class="position-relative">
        <div class="shape shape-bottom shape-fluid-x svg-shim text-light">
          <Curve1/>
        </div>
      </div>

      <CardsSection {...{currentUser, onCTAClickIfLoggedIn, loadingButtonIndex, signInAnonymously, }}/>

      <FAQ/>
    </Layout>
  )
}

export default Beta
