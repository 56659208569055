
const firebaseFunctionsBaseURL = process.env.FIREBASE_FUNCTIONS_BASE_URL

// pass in url and params object
// to encode params into url as query string
const makeURLWithQueryParams = ({url, params}) => {
  const encodedParams = (new URLSearchParams(params)).toString()
  return `${url}?${encodedParams}`
}
// abstracts out json request
// specifies json format in headers
// stringifies body if needed
// throws error if not a 2XX status
// returns promise with parsed json response
const makeJSONRequest = ({
  url,
  method,
  body, // accepts object, will stringify
}) => {
  const bodyString = body ? JSON.stringify(body) : null
  return fetch(
    url,
    {
      method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: bodyString
    })
  .then(response => {
    if (!response.ok) {
      throw new Error(`Response status not ok. Status: ${response.status}`)
    }
    // this throws exception if response is empty. don't need to change now
    // maybe later
    return response.json()
  })
}

// more convenient way to make request to firebase functions
// specify path
// encodes params into url
const makeJSONRequestToFirebaseFunctions = ({
  path,
  method,
  body, // typically for POST
  params, // typically for GET
}) => {
  let url = `${firebaseFunctionsBaseURL}/${path}`
  if (params) {
    url = makeURLWithQueryParams({
      url,
      params,
    })
  }
  return makeJSONRequest({
    url,
    method,
    body,
  })
}

const createCheckoutSession = ({
                                mode,
                                line_items,
                                success_url,
                                cancel_url,
                                subscription_data,
                                userUID,
                              }) => {
  return makeJSONRequestToFirebaseFunctions({
    path: 'create_checkout_session',
    method: 'POST',
    body: {
      line_items,
      mode,
      success_url,
      cancel_url,
      subscription_data,
      userUID,
    }
  })
}

const createCustomerPortalSession = ({
                                customerId,
                                returnURL,
                              }) => {
  return makeJSONRequestToFirebaseFunctions({
    path: 'create_customer_portal_session',
    method: 'POST',
    body: {
      customerId,
      returnURL,
    }
  })
}

const getUserSubscription = ({
  userUID
}) => {
  // fake valid subscription for development
  // DO NOT leave like this outside of development:
  // return Promise.resolve({
  //   userUID,
  //   timeRemaining: 999,
  // })
  //
  return makeJSONRequestToFirebaseFunctions({
    path: 'user_subscription',
    method: 'GET',
    params: {
      userUID
    },
  })
}

// MARK: - BETA

const createBetaUserSubscription = ({
                                userUID,
                              }) => {
  return makeJSONRequestToFirebaseFunctions({
    path: 'create_beta_user_subscription',
    method: 'POST',
    body: {
      userUID,
    }
  })
}

// MARK: - PROXYCURL

const getLinkedInProfile = ({ url }) => {
  return makeJSONRequestToFirebaseFunctions({
    path: 'get_linked_in_profile',
    method: 'GET',
    params: {
      url,
    }
  })
}

const resolveAndGetLinkedInProfile = ({
                                        company_domain,
                                        first_name,
                                        last_name,
                                        // location,
                                        title,
                                      }) => {

  return makeJSONRequestToFirebaseFunctions({
    path: 'resolve_and_get_linked_in_profile',
    method: 'GET',
    params: {
      company_domain,
      first_name,
      last_name,
      // location,
      title,
    }
  })
}


export { createCheckoutSession, getUserSubscription, createCustomerPortalSession, createBetaUserSubscription, getLinkedInProfile, resolveAndGetLinkedInProfile, }
